@import '/styles/vars';

.container {
    @include mobile {
        margin: 0 -12px;
        overflow-x: auto;
        overflow-y: hidden;
    }
}

.posts {
    display: flex;
    margin: 0 -12px;
    @include mobile {
        margin: 0 -9px;
        width: 860px;
        margin: 0;
    }
}

.post {
    padding: 0 12px;
    width: 33.33%;
    h3 {
        font-weight: normal;
        font-size: 19px;
        line-height: 1.33;
        @include mobile {
            font-size: $font16;
        }
    }
    @include mobile {
        padding: 0 9px;
    }
}

.image {
    border-radius: $radiusImg;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
